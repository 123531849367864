import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Observer from './Observer'
const NewsletterForm = dynamic(() => import('./NewsLetterForm'))
const FirstColumn = dynamic(() => import('./footer-partiales/FirstColumn'))
const SecondColumn = dynamic(() => import('./footer-partiales/SecondColumn'))

function Footer() {
    const router = useRouter()
    const currentPath = router.pathname
    return <footer>
        <Observer>
        <div className="container">
            <NewsletterForm/>

            <div className="footer-links">
                <FirstColumn currentPath={currentPath}/>
                <SecondColumn/>
            </div>
        </div>
        </Observer>
    </footer>
}

export default Footer
